import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';

const supabase = createClient(process.env.REACT_APP_SUPABASE_PROJECT_URL, process.env.REACT_APP_SUPABASE_KEY);

export default function SubmitProposal() {
  const { proposal_id } = useParams();
  const [link, setLink] = useState('');
  const [userUID, setUserUID] = useState(null);
  const [platform, setPlatform] = useState('');
  const [verificationError, setVerificationError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchUser() {
      const { data: { user }, error } = await supabase.auth.getUser();
      if (error) {
        console.error('Error fetching user:', error.message);
        return;
      }
      if (user) {
        setUserUID(user.id);
      } else {
        navigate('/login'); // Redirect to login page if not authenticated
      }
    }

    async function fetchProposal() {
      const { data: proposal, error } = await supabase
        .from('proposals')
        .select('platform')
        .eq('id', proposal_id)
        .single();

      if (error) {
        console.error('Error fetching proposal:', error.message);
        return;
      }

      if (proposal) {
        setPlatform(proposal.platform);
      }
    }

    fetchUser();
    fetchProposal();
  }, [navigate, proposal_id]);

  const isValidLink = (url) => {
    if (platform === 'YouTube') {
      return url.includes('youtube.com') || url.includes('youtu.be');
    } else if (platform === 'TikTok') {
      return url.includes('tiktok.com');
    } else if (platform === 'Any') {
      return url.includes('youtube.com') || url.includes('youtu.be') || url.includes('tiktok.com');
    }
    return false;
  };

  const extractYouTubeVideoID = (url) => {
    // Handle standard YouTube URLs
    let standardMatch = url.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:watch\?v=|embed\/|v\/|.+\?v=)([\w-]{11})/);
    if (standardMatch) return standardMatch[1];

    // Handle YouTube Shorts URLs
    let shortsMatch = url.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/shorts\/([\w-]{11})/);
    if (shortsMatch) return shortsMatch[1];

    // Handle shortened youtu.be URLs
    let shortMatch = url.match(/(?:https?:\/\/)?(?:www\.)?youtu\.be\/([\w-]{11})/);
    if (shortMatch) return shortMatch[1];

    return null;
  };

  const extractTikTokID = (url) => {
    try {
      // First try to extract from standard formats
      const patterns = [
        // Handle URL with query parameters
        /tiktok\.com\/@[\w.-]+\/video\/(\d+)(?:\?[^?]*)?$/,
        // Handle clean URLs
        /video\/(\d+)/,
        /tiktok\.com\/@[\w.-]+\/video\/(\d+)/,
        // Handle pure numeric IDs
        /(\d{19})/
      ];

      for (let pattern of patterns) {
        const match = url.match(pattern);
        if (match && match[1]) {
          return match[1];
        }
      }

      // If standard patterns fail, try parsing as URL and extracting from path
      const urlObj = new URL(url);
      const pathParts = urlObj.pathname.split('/');
      const videoIndex = pathParts.indexOf('video');
      if (videoIndex !== -1 && pathParts[videoIndex + 1]) {
        const potentialId = pathParts[videoIndex + 1].split('?')[0]; // Remove query params
        if (/^\d+$/.test(potentialId)) { // Verify it's numeric
          return potentialId;
        }
      }

      return null;
    } catch (error) {
      console.error('Error parsing TikTok URL:', error);
      return null;
    }
  };

  const verifyVideoOwnership = async (videoIdentifier, platform) => {
    try {
      // Fetch the user's record from public_users table
      const { data: userData, error: userError } = await supabase
        .from('public_users')
        .select('videos')
        .eq('id', userUID)
        .single();

      if (userError) {
        console.error('Error fetching user videos:', userError.message);
        return false;
      }

      if (!userData?.videos || !Array.isArray(userData.videos)) {
        console.error('No videos found for user');
        return false;
      }

      // Check if the video exists in the user's videos array
      return userData.videos.some(video => {
        if (platform === 'YouTube') {
          return video.type === 'youtube' && video.youtube === videoIdentifier;
        } else if (platform === 'TikTok') {
          return video.tiktok === videoIdentifier;
        }
        return false;
      });

    } catch (error) {
      console.error('Error verifying video ownership:', error);
      return false;
    }
  };

  const fetchYouTubeVideoTitle = async (videoID) => {
    const apiKey = process.env.REACT_APP_YOUTUBE_API_KEY;
    const response = await fetch(`https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoID}&key=${apiKey}`);
    const data = await response.json();
    return data.items[0]?.snippet?.title || 'Unknown Title';
  };

  const handleSubmission = async () => {
    setVerificationError('');  // Clear any previous errors
    
    if (!isValidLink(link)) {
      return { success: false, error: 'Invalid link format' };
    }

    if (link.includes('youtube.com') || link.includes('youtu.be')) {
      const videoID = extractYouTubeVideoID(link);
      if (!videoID) {
        return { success: false, error: 'Invalid YouTube link' };
      }

      // Verify ownership first
      const isOwner = await verifyVideoOwnership(videoID, 'YouTube');
      if (!isOwner) {
        setVerificationError('YouTube');
        return undefined; // Return undefined to prevent further processing
      }

      try {
        const title = await fetchYouTubeVideoTitle(videoID);
        return { success: true, title };
      } catch (error) {
        console.error('Error fetching YouTube video:', error);
        return { success: false, error: 'Error processing YouTube video' };
      }
    } 
    
    if (link.includes('tiktok.com')) {
      const tiktokID = extractTikTokID(link);
      if (!tiktokID) {
        return { success: false, error: 'Invalid TikTok link' };
      }

      const isOwner = await verifyVideoOwnership(tiktokID, 'TikTok');
      if (!isOwner) {
        setVerificationError('TikTok');
        return undefined; // Return undefined to prevent further processing
      }

      return { success: true, title: 'TikTok Video' };
    }

    return { success: false, error: 'Invalid link type' };
  };

  async function submit(e) {
    e.preventDefault();

    if (!userUID) {
      alert('You must be logged in to submit your video.');
      return;
    }

    if (!link) {
      alert('Please provide a link.');
      return;
    }

    const result = await handleSubmission();
    
    // Prevent submission if result is undefined (verification failed) or success is false
    if (!result) {
      alert('Video ownership could not be verified.');
      return;
    }
    
    if (!result.success) {
      alert(result.error);
      return;
    }

    try {
      // Insert the new submission
      const { data: submissionData, error: submissionError } = await supabase
        .from('submissions')
        .insert([{ link, title: result.title, proposal_id, created_by: userUID }])
        .select();

      if (submissionError) throw submissionError;

      // If successful, retrieve the proposal
      const { data: proposalData, error: proposalError } = await supabase
        .from('proposals')
        .select('num_submissions')
        .eq('id', proposal_id)
        .single();

      if (proposalError) throw proposalError;

      // Increment the submission count
      const newSubmissionCount = proposalData.num_submissions + 1;

      // Update the proposal with the new submission count
      const { error: updateError } = await supabase
        .from('proposals')
        .update({ num_submissions: newSubmissionCount })
        .eq('id', proposal_id);

      if (updateError) throw updateError;

      alert('Video submitted successfully!');

      // Reset form fields after successful submission
      setLink('');
      setVerificationError(''); // Clear verification error on successful submission

      // Redirect to the ExpandedProposal page
      navigate(`/expanded-proposal/${proposal_id}`);
    } catch (error) {
      console.error('Error submitting video:', error.message);
      alert('Error submitting proposal. Please try again.');
    }
  }

  return (
    <div>
      <section className="bg-gray-100">
        <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-5">
            <div className="lg:col-span-2 lg:py-12">
              <p className="max-w-xl text-lg">
                Submit the link to your content to complete this proposal.
                If your video is selected, you will be rewarded via the payment method in your Profile.
              </p>
              <br />
              <p className="text-red-500">
                Must be posted on {platform === 'Any' ? 'YouTube or TikTok' : platform}.
              </p>
              {verificationError && (
                <p className="mt-4 text-red-500">
                  Make sure your {verificationError} account is connected in{' '}
                  <a href="/profile" className="font-medium hover:text-red-700">Profile</a>.
                </p>
              )}
            </div>
            <div className="rounded-lg bg-white p-8 shadow-lg lg:col-span-3 lg:p-12">
              <form onSubmit={submit} className="space-y-4">
                <div>
                  <label htmlFor="link" className="sr-only">Link to your video</label>
                  <input
                    type="text"
                    id="link"
                    className="w-full rounded-lg border-gray-200 p-3 text-sm"
                    placeholder="Link to your video"
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                    required
                  />
                </div>
                <div className="mt-4">
                  <button
                    type="submit"
                    className="inline-block w-full rounded-lg bg-black px-5 py-3 font-medium text-white sm:w-auto"
                  >
                    Submit Your Content!
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}