import React from 'react'
import Header from '../components/Header.jsx';
import SubmitTextBox from '../components/Proposal.jsx';
import SubmitIdea from '../components/SubmitIdea.jsx';
export default function Submit() {
  return (
    <div>
      <SubmitIdea />
    </div>
  )
}
