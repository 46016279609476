import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { SupabaseClient, createClient } from "@supabase/supabase-js";
import Header from './components/Header.jsx';
import Footer from './components/Footer.jsx';

import * as Pages from './pages';
import ScrollToTop from './components/ScrollToTop.jsx';

const supabase = createClient(process.env.REACT_APP_SUPABASE_PROJECT_URL, process.env.REACT_APP_SUPABASE_KEY);

export default function App() {
  const [session, setSession] = useState(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header session={session} />
      <Routes>
        <Route path="/" element={<Pages.LandingPage />} />

        {/* User Management */}
        <Route path="/login" element={<Pages.Login />} />
        <Route path="/onboard" element={<Pages.Onboard />} />
        <Route path="/profile" element={<Pages.Profile />} />
        <Route path="/auth/callback/" element ={<Pages.Redirect />} />
        <Route path="/auth/callback/youtube/" element ={<Pages.YouTubeRedirect />} />
        
        {/* Handle Bounties */}
        <Route path="/submit" element={<Pages.Submit />} />
        <Route path="/ideas" element={<Pages.Ideas />} />
        <Route path="/search" element={<Pages.Search />} />
        <Route path="/expanded-proposal" element={<Pages.ExpandedProposal />} />
        <Route path="/expanded-proposal/:proposal_id" element={<Pages.ExpandedProposal />} />
        <Route path="/submit-content" element={<Pages.SubmitContent />} />
        <Route path="/submit-content/:proposal_id" element={<Pages.SubmitContent />} />
        <Route path="/contribution/:session_id" element={<Pages.Contribution />} />
        <Route path="/discover" element={<Pages.Discover />} />
        

        {/* Legal */}
        <Route path="/privacy-policy" element={<Pages.PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<Pages.TOS />} />

        {/* 404 error page */}
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}
