import React, { useState } from 'react';
import SearchBox from '../components/SearchBox.jsx';
import Proposal from '../components/Proposal.jsx';

export default function Discover() {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  return (
    <div>
      <section>
        <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
          <SearchBox onSearch={handleSearch} />
          
          <h1 className="text-3xl font-bold text-gray-800 mb-4 mt-4">Completed Bounties</h1>
          <Proposal param="completed" searchQuery={searchQuery} />
        </div>
      </section>
    </div>
  );
}