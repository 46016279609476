import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Proposal from '../components/Proposal.jsx';
import SearchBox from '../components/SearchBox.jsx';

export default function Search() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initialQuery = searchParams.get('q') || '';
  const [query, setQuery] = useState(initialQuery);

  useEffect(() => {
    setQuery(initialQuery);
  }, [initialQuery]);

  const handleSearch = (newQuery) => {
    setQuery(newQuery);
    window.history.pushState(null, '', `/search?q=${encodeURIComponent(newQuery)}`);
  };

  return (
    <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
      <SearchBox onSearch={handleSearch} initialQuery={query} />
      
      <h1 className="text-3xl font-bold text-gray-800 mb-4 mt-4">
        {query === "" ? "All Bounties" : `Bounties related to "${query}"`}
      </h1>
      
      <Proposal 
        param="search" 
        searchQuery={query}
        emptyStateMessage={
          <div className="text-center mt-16">
            <p className="text-3xl text-gray-500">No bounties found for "{query}"</p>
            <p className="text-gray-500 sm:text-xl mt-2">
              Don't see your idea?{' '}
              <Link 
                to="/submit" 
                className="text-blue-600 hover:text-blue-800 hover:underline"
              >
                Create a bounty!
              </Link>
            </p>
          </div>
        }
      />
    </div>
  );
}