import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { useNavigate } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';
import axios from 'axios';
import { supabase } from '../supabaseClient'; 

export default function LandingPage() {
  const [selectedTab, setSelectedTab] = useState('viewers');
  const [stats, setStats] = useState({
    activeUsers: 0,
    bounties: 0,
    originalVideos: 0,
  });
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchStats = async () => {
      try {
        // Use the correct /server prefix
        const response = await fetch('/server/api/stats', {
          credentials: 'include',
          headers: {
            'Accept': 'application/json'
          }
        });
        
        if (!response.ok) {
          throw new Error(`Failed to fetch stats: ${response.status} ${response.statusText}`);
        }
        const data = await response.json();
        setStats(data);
      } catch (error) {
        console.error('Error fetching stats:', error.message);
        // Set default values in case of error
        setStats({
          activeUsers: 0,
          bounties: 0,
          originalVideos: 0
        });
      }
    };

    fetchStats();
  }, []);

  useEffect(() => {
    const checkUser = async () => {
      try {
        const { data: { user: authUser }, error: authError } = await supabase.auth.getUser();
  
        if (authError) {
          console.error('Auth error:', authError);
          setUser(null);
          return;
        }
  
        if (!authUser) {
          console.log('No authenticated user');
          setUser(null);
          return;
        }
  
        const { data: { session } } = await supabase.auth.getSession();
        
        const response = await fetch('/server/api/check-user', {
          headers: {
            'Authorization': `Bearer ${session?.access_token}`,
          }
        });
  
        if (!response.ok) {
          throw new Error(`Server error: ${response.status}`);
        }
  
        const { user: userData, needsOnboarding } = await response.json();
        setUser(userData);
  
        if (needsOnboarding) {
          navigate('/onboard');
        }
      } catch (error) {
        console.error('Error checking user:', error);
        setUser(null);
      }
    };
  
    checkUser();
  
    const { data: { subscription } } = supabase.auth.onAuthStateChange(() => {
      checkUser();
    });
  
    return () => {
      subscription?.unsubscribe();
    };
  }, [navigate]);
  

  const SubmitTextBox = () => {
    const handleInputFocus = () => {
      if (user) {
        navigate('/submit');
      } else {
        navigate('/login');
      }
    };
  
    const handleArrowClick = () => {
      if (user) {
        navigate('/submit');
      } else {
        navigate('/login');
      }
    };
  
    return (
      <form action="" className="mx-auto mt-8 max-w-md space-y-4">
        <div>
          <div className="relative">
            <input
              type="text"
              id="idea"
              className="w-full rounded-lg border-gray-200 p-4 pr-10 text-sm shadow-sm"
              placeholder=""
              onFocus={handleInputFocus}
              readOnly
            />
  
            <TypeAnimation
              sequence={[
                'My dream video idea is...',
                3000,
                'I wish someone made a video about...',
                3000,
              ]}
              speed={25}
              style={{
                position: 'absolute',
                top: '50%',
                left: '16px',
                transform: 'translateY(-50%)',
                pointerEvents: 'none',
              }}
              wrapper="span"
              repeat={Infinity}
            />
  
            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-6 w-6 text-gray-600 hover:text-gray-900 cursor-pointer"
                onClick={handleArrowClick}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
            </div>
          </div>
        </div>
      </form>
    );
  };
  
  return (
    <div>
      <section className="bg-white">
        <div className="mx-auto max-w-screen-xl px-4 py-12 sm:px-6 md:py-16 lg:px-8">
          <div className="mx-auto max-w-3xl text-center">
            <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl">Bounties</h2>
            <p className="mt-4 text-gray-500 font-semibold sm:text-xl">
              Crowdfund and discover original content.
            </p>
          </div>
          <br />
          <div className="mx-auto max-w-3xl text-center">
            <h2 className="text-2xl font-bold text-gray-900 sm:text-2xl">How It Works</h2>
            <br />
            <span className="inline-flex -space-x-px overflow-hidden rounded-md border bg-white shadow-sm">
              <button
                className={`inline-block px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:relative ${selectedTab === 'creators' ? 'bg-gray-200' : ''}`}
                onClick={() => setSelectedTab('creators')}
              >
                Creators
              </button>
              <button
                className={`inline-block px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:relative ${selectedTab === 'viewers' ? 'bg-gray-200' : ''}`}
                onClick={() => setSelectedTab('viewers')}
              >
                Viewers
              </button>
              <button
                className={`inline-block px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:relative ${selectedTab === 'businesses' ? 'bg-gray-200' : ''}`}
                onClick={() => setSelectedTab('businesses')}
              >
                Businesses
              </button>
            </span>

            <div className="mt-8 space-y-8">
              {selectedTab === 'businesses' && (
                <div className="flex flex-col items-center">
                  <div className="text-gray-500 sm:text-xl">
                    <div>1. Post and fund ideas for your product.</div>
                    <div className="my-4">
                      <a href="" className="scroll-down-link scroll-down-arrow"></a>
                    </div>
                    <div>2. Creators make videos with your product.</div>
                    <div className="my-4">
                      <a href="" className="scroll-down-link scroll-down-arrow"></a>
                    </div>
                    <div>3. Use the best submissions for marketing!</div>
                  </div>
                </div>
              )}

              {selectedTab === 'creators' && (
                <div className="flex flex-col items-center">
                  <div className="text-gray-500 sm:text-xl">
                    <div>1. Find an original idea to use for your video.</div>
                    <div className="my-4">
                      <a href="" className="scroll-down-link scroll-down-arrow"></a>
                    </div>
                    <div>2. Submit a link to your video.</div>
                    <div className="my-4">
                      <a href="" className="scroll-down-link scroll-down-arrow"></a>
                    </div>
                    <div>3. Get rewarded for being the best submission!</div>
                  </div>
                </div>
              )}

              {selectedTab === 'viewers' && (
                <div className="flex flex-col items-center">
                  <div className="text-gray-500 sm:text-xl">
                    <div>1. Post your original idea (bounty).</div>
                    <div className="my-4">
                      <a href="" className="scroll-down-link scroll-down-arrow"></a>
                    </div>
                    <div>2. Creators make videos about your idea.</div>
                    <div className="my-4">
                      <a href="" className="scroll-down-link scroll-down-arrow"></a>
                    </div>
                    <div>3. Choose the best video to get rewarded!</div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <br />
          <div className="mx-auto max-w-3xl text-center">
            <h2 className="text-2xl font-bold text-gray-900 sm:text-2xl">Join the Movement</h2>
          </div>
                
          <div className="mt-4">
            <dl className="grid grid-cols-1 gap-4 sm:grid-cols-3 sm:divide-x sm:divide-gray-100">
              <div className="flex flex-col px-4 py-8 text-center">
                <dt className="order-last text-lg font-medium text-gray-500">Users</dt>
                <dd className="text-4xl font-extrabold text-blue-600 md:text-5xl">
                  <CountUp end={stats.activeUsers} duration={2} />
                </dd>
              </div>
              <div className="flex flex-col px-4 py-8 text-center">
                <dt className="order-last text-lg font-medium text-gray-500">Bounties</dt>
                <dd className="text-4xl font-extrabold text-amber-600 md:text-5xl">
                  <CountUp end={stats.bounties} duration={2} />
                </dd>
              </div>
              <div className="flex flex-col px-4 py-8 text-center">
                <dt className="order-last text-lg font-medium text-gray-500">Original Videos Created</dt>
                <dd className="text-4xl font-extrabold text-teal-600 md:text-5xl">
                  <CountUp end={stats.originalVideos} duration={2} />
                </dd>
              </div>
            </dl>
          </div>
          <SubmitTextBox /> 
        </div>
      </section>
    </div>
  );
}