import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function SearchBox({ onSearch, initialQuery = '' }) {
  const [query, setQuery] = useState(initialQuery);
  const navigate = useNavigate();

  useEffect(() => {
    setQuery(initialQuery);
  }, [initialQuery]);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`/search?q=${encodeURIComponent(query)}`);
    onSearch(query);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4 max-w-lg" autoComplete="off">
      <div>
        <div>
          <label htmlFor="search" className="sr-only">
            Search
          </label>
          <div className="relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="absolute inset-y-4 left-4 h-6 text-gray-600 pointer-events-none"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              />
            </svg>

            <input
              type="text"
              id="search"
              value={query}
              onChange={handleInputChange}
              className="w-full rounded-lg border-gray-200 p-4 pl-12 text-md shadow-sm font-semibold"
              placeholder="Search for bounties"
            />
          </div>
        </div>
      </div>
    </form>
  );
}