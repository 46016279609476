import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
// import logo from '../assets/green-logo.svg';
import logo from '../assets/logo.svg';

const Header = ({ session }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  return (
    <header className="bg-white relative z-50">
      <div className="mx-auto flex h-16 max-w-screen-xl items-center gap-8 px-4 sm:px-6 lg:px-8">
        <a className="block text-teal-600" href="/">
          <span className="sr-only">Home</span>
          <img src={logo} alt="Logo" className="h-16" />
        </a>

        <div className="flex flex-1 items-center justify-end md:justify-between">
          <nav aria-label="Global" className="hidden md:block">
            <ul className="flex items-center gap-6 text-sm">
              <li>
                <Link to="/ideas" className="text-gray-500 font-semibold transition hover:text-gray-500/75">
                  Bounties
                </Link>
              </li>
              <li>
                <Link to="/discover" className="text-gray-500 font-semibold transition hover:text-gray-500/75">
                  Discover
                </Link>
              </li>
            </ul>
          </nav>

          <div className="flex items-center gap-4">
            {session ? (
              <Link
                to="/profile"
                className="block rounded-md bg-teal-600 px-5 py-2.5 text-sm font-medium text-white transition hover:bg-teal-700"
              >
                Profile
              </Link>
            ) : (
              <div className="sm:flex sm:gap-4">
                <Link
                  to="/login" state={{ from: location }}
                  className="block rounded-md bg-teal-600 px-5 py-2.5 text-sm font-medium text-white transition hover:bg-teal-700"
                >
                  Login
                </Link>

                <Link
                  to="/login" state={{ from: location }}
                  className="hidden rounded-md bg-gray-100 px-5 py-2.5 text-sm font-medium text-teal-600 transition hover:text-teal-600/75 sm:block"
                >
                  Register
                </Link>
              </div>
            )}

            <button
              className="block rounded bg-gray-100 p-2.5 text-gray-600 transition hover:text-gray-600/75 md:hidden"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <span className="sr-only">Toggle menu</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu, hidden by default */}
      <div className={`absolute inset-x-0 top-full mt-2 origin-top-right bg-white shadow-lg ring-1 ring-black ring-opacity-5 ${isMenuOpen ? 'block' : 'hidden'} md:hidden`}>
        <div className="px-2 py-3">
          <ul className="space-y-2 text-sm">
            <li>
              <Link
                to="/ideas"
                className="block px-4 py-2 font-semibold text-gray-700 transition hover:bg-gray-100"
                onClick={() => setIsMenuOpen(false)}
              >
                Bounties
              </Link>
            </li>
            <li>
              <Link
                to="/discover"
                className="block px-4 py-2 font-semibold text-gray-700 transition hover:bg-gray-100"
                onClick={() => setIsMenuOpen(false)}
              >
                Discover
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
