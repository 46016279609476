import React, { useState } from 'react';
import { toast } from 'react-hot-toast';

const StripeCheckout = ({ postId, amount, userId }) => {
  const [loading, setLoading] = useState(false);

  const handleCheckout = async () => {
    try {
      setLoading(true);
      const toastId = toast.loading('Preparing checkout...');

      // Ensure amount is at least $1
      const minAmount = 100; // $1 in cents
      const amountInCents = Math.max(Math.round(amount * 100), minAmount);

      const response = await fetch('/server/api/stripe/create-checkout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          postId,
          amount: amountInCents,
          type: 'proposal',
          userId: userId || null,  
          productId: 'prod_R9bWQLkbqQsfrP', // Your name-your-price product ID
          mode: 'payment',
          currency: 'usd',
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to create checkout session');
      }

      const { url } = await response.json();
      
      if (url) {
        // Update loading toast before redirect
        toast.success('Redirecting to secure payment', { id: toastId });
        window.location.href = url;
      } else {
        throw new Error('Invalid checkout session URL');
      }
    } catch (error) {
      console.error('Payment initiation error:', error);
      toast.error(`Payment failed: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Calculate progress percentage (based on a $100 target)
  const progressPercentage = Math.min((amount / 100) * 100, 100);

  return (
    <div className="relative flex items-center rounded-md min-w-[200px] h-[30px] bg-gray-100 overflow-hidden">
      {/* Progress bar background */}
      <div 
        className="absolute left-0 top-0 h-full transition-all duration-500 ease-in-out"
        style={{ 
          width: `${progressPercentage}%`,
          background: 'linear-gradient(to right, #22c55e, #22c55e 95%)'
        }}
      />
      
      {/* Content overlay */}
      <div className="relative flex justify-between items-center w-full px-2">
        <span className="text-sm font-bold z-10 text-white">
          ${amount.toFixed(2)}
        </span>
        <button 
          onClick={handleCheckout}
          className="text-gray-600 hover:text-gray-900 z-10 disabled:opacity-50"
          disabled={loading}
          aria-label="Proceed to payment"
        >
          {loading ? (
            <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
            </svg>
          )}
        </button>
      </div>
      
      {/* Target amount label */}
      <span className="absolute right-0 -bottom-5 text-xs text-gray-500">
        $100
      </span>
    </div>
  );
};

export default StripeCheckout;