import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';

const supabase = createClient(process.env.REACT_APP_SUPABASE_PROJECT_URL, process.env.REACT_APP_SUPABASE_KEY);

export default function Profile() {
  const [videos, setVideos] = useState([]);
  const [displayName, setDisplayName] = useState('');
  const [user, setUser] = useState({ 
    account_type: '', 
    preferred_platform: '',
    platforms: {
      tiktok: { connected: false, last_sync: null },
      youtube: { connected: false, last_sync: null }
    }
  });
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [submissions, setSubmissions] = useState([]);
  const [proposals, setProposals] = useState([]);
  const [selectedTab, setSelectedTab] = useState('bounties');
  const [isProcessingTikTok, setIsProcessingTikTok] = useState(false);
  const [proposalContributions, setProposalContributions] = useState({});
  const [userTotalContributions, setUserTotalContributions] = useState(0);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const location = useLocation();

  // Format date helper function
  const formatDate = (dateString) => {
    if (!dateString) return null;
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const fetchContributions = async (userUID, proposalIds) => {
    try {
      console.log('Fetching contributions for:', { userUID, proposalIds });
      
      // For each proposal, get both total contributions and user's contributions
      const proposalContribPromises = proposalIds.map(async (propId) => {
        // Get total contributions for this proposal
        const { data: totalContributions, error: totalError } = await supabase
          .from('test_transactions')
          .select('amount')
          .eq('post_id', propId);
  
        if (totalError) throw totalError;
  
        // Get user's contributions for this proposal
        const { data: userContributions, error: userError } = await supabase
          .from('test_transactions')
          .select('amount')
          .eq('post_id', propId)
          .eq('user_id', userUID);
  
        if (userError) throw userError;
  
        // Calculate sums
        const totalAmount = totalContributions?.reduce((sum, tx) => sum + tx.amount, 0) || 0;
        const userAmount = userContributions?.reduce((sum, tx) => sum + tx.amount, 0) || 0;
  
        return {
          proposalId: propId,
          totalAmount,
          userAmount
        };
      });
  
      const results = await Promise.all(proposalContribPromises);
  
      // Create contribution maps
      const contribMap = results.reduce((acc, curr) => {
        acc[curr.proposalId] = {
          total: curr.totalAmount,
          user: curr.userAmount
        };
        return acc;
      }, {});
  
      // Calculate total user contributions across all proposals
      const totalUserContrib = results.reduce((sum, curr) => sum + curr.userAmount, 0);
  
      // Update state
      setProposalContributions(contribMap);
      setUserTotalContributions(totalUserContrib);
  
      console.log('Contribution data:', {
        contributions: contribMap,
        userTotal: totalUserContrib,
        userId: userUID
      });
  
    } catch (error) {
      console.error('Error fetching contributions:', error);
    }
  };
  
  const request_youtube_token = async () => {
    try {
      const response = await axios.get(
        'https://bountiesapp.com/server/youtube/oauth',
        { 
          withCredentials: true,
          headers: {
            'Accept': 'application/json',
            'Cache-Control': 'no-cache'
          }
        }
      );
  
      if (response.data?.url) {
        console.log("Received YouTube auth URL:", response.data.url);
        // Store the return path
        localStorage.setItem('returnToSettings', 'true');
        // Perform client-side redirect
        window.location.href = response.data.url;
      } else {
        throw new Error("No authorization URL received");
      }
    } catch (error) {
      console.error("Error starting YouTube authorization:", error);
      setError("Failed to start YouTube authorization: " + (error.response?.data?.error || error.message));
    }
  };
  

  useEffect(() => {
    // Check for TikTok auth state
    if (location.state?.tiktokAuth) {
      if (location.state.tiktokAuth === 'success') {
        setError(null);
        // Optionally show success message
        // setSuccessMessage(location.state.message);
      } else if (location.state.tiktokAuth === 'error') {
        setError(location.state.error);
      }
      
      // Clear the state
      window.history.replaceState({}, document.title);
    }
  }, [location]);


  const fetchProposals = async (userUID) => {
    try {
      // Fetch proposals
      const { data: proposalsData, error: proposalsError } = await supabase
        .from('proposals')
        .select('*')
        .eq('created_by', userUID);
  
      if (proposalsError) {
        console.error('Error fetching proposals:', proposalsError.message);
        return [];
      }
  
      // Get submission counts for each proposal
      const proposalsWithCounts = await Promise.all(proposalsData.map(async (proposal) => {
        // Get submission count
        const { count: submissionCount, error: countError } = await supabase
          .from('submissions')
          .select('id', { count: 'exact' })
          .eq('proposal_id', proposal.id);
  
        if (countError) {
          console.error('Error getting submission count:', countError.message);
        }
  
        return {
          ...proposal,
          submissionCount: submissionCount || 0
        };
      }));
  
      setProposals(proposalsWithCounts);
      return proposalsWithCounts; // Return the data for use in contribution fetching
  
    } catch (error) {
      console.error('Unexpected error fetching proposals:', error.message);
      return [];
    }
  };

  const fetchSubmissions = async (userUID) => {
    try {
      const { data, error } = await supabase
        .from('submissions')
        .select('*')
        .eq('created_by', userUID);

      if (error) {
        console.error('Error fetching submissions:', error.message);
      } else {
        setSubmissions(data);
      }
    } catch (error) {
      console.error('Unexpected error fetching submissions:', error.message);
    }
  };

  const fetchUserData = async (userUID) => {
    try {
      const { data: userData, error: userError } = await supabase
        .from('public_users')
        .select('display_name, account_type, preferred_platform, platforms')
        .eq('id', userUID)
        .single();

      if (userError) {
        console.error('Error fetching user data:', userError.message);
      } else if (userData) {
        setDisplayName(userData.display_name);
        setUser({
          account_type: userData.account_type || '',
          preferred_platform: userData.preferred_platform || '',
          platforms: userData.platforms || {
            tiktok: { connected: false, last_sync: null },
            youtube: { connected: false, last_sync: null }
          }
        });
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };


  const request_token = async () => {
    try {
      const response = await axios.get(
        'https://bountiesapp.com/server/oauth',
        { 
          withCredentials: true,
          headers: {
            'Accept': 'application/json',
            'Cache-Control': 'no-cache'
          }
        }
      );

      if (response.data?.url) {
        console.log("Redirecting to TikTok auth URL:", response.data.url);
        localStorage.setItem('returnToSettings', 'true');
        window.location.href = response.data.url;
      } else {
        throw new Error("No authorization URL received");
      }
    } catch (error) {
      console.error("Error starting TikTok authorization:", error);
      setError("Failed to start TikTok authorization: " + (error.response?.data?.error || error.message));
    }
  };

  const handleTikTokCallback = async (code, state) => {
    if (isProcessingTikTok) return;
    setIsProcessingTikTok(true);
  
    try {
      const response = await axios.post(
        'https://bountiesapp.com/server/tiktokaccesstoken',
        { code, state },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Cache-Control': 'no-cache'
          }
        }
      );
  
      if (!response.data || !Array.isArray(response.data)) {
        throw new Error("Invalid response format from server");
      }
  
      const session = await supabase.auth.getSession();
      const userId = session.data.session?.user.id;
  
      if (!userId) {
        throw new Error("No user session found");
      }
  
      const { data: userData, error: fetchError } = await supabase
        .from('public_users')
        .select('videos')
        .eq('id', userId)
        .single();
  
      if (fetchError) throw fetchError;
  
      // Keep the existing structure from TikTok API
      const newVideos = response.data;
      const existingVideos = userData.videos || [];
      
      // Combine while avoiding duplicates
      const updatedVideos = [
        ...existingVideos,
        ...newVideos.filter(newVideo => 
          !existingVideos.some(existingVideo => 
            existingVideo.tiktok === newVideo.tiktok
          )
        )
      ];
  
      const { error: upsertError } = await supabase
        .from('public_users')
        .update({ videos: updatedVideos })
        .eq('id', userId);
  
      if (upsertError) throw upsertError;
  
      console.log("TikTok videos saved successfully");
      await fetchVideos();
      setError(null);
  
    } catch (error) {
      console.error("Error processing TikTok callback:", error);
      const errorMessage = error.response?.data?.error || error.message;
      setError(`Failed to connect TikTok account: ${errorMessage}`);
    } finally {
      setIsProcessingTikTok(false);
    }
  };

  const initializeGoogleAPI = () => {
    window.gapi.load('client:auth2', () => {
      window.gapi.auth2.init({ client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID })
        .then(() => {
          console.log("Google API client initialized");
        })
        .catch(err => {
          console.error("Error initializing Google API client:", err);
        });
    });
  };

  const authenticate = () => {
    return window.gapi.auth2.getAuthInstance()
      .signIn({ scope: "https://www.googleapis.com/auth/youtube.readonly" })
      .then(() => {
        console.log("Sign-in successful");
      })
      .catch(err => {
        console.error("Error signing in:", err);
      });
  };

  const loadClient = () => {
    window.gapi.client.setApiKey(process.env.REACT_APP_YOUTUBE_API_KEY);
    return window.gapi.client.load("https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest")
      .then(() => {
        console.log("GAPI client loaded for API");
      })
      .catch(err => {
        console.error("Error loading GAPI client for API:", err);
      });
  };

  const executeYouTube = async (userId) => {
    try {
      const response = await window.gapi.client.youtube.channels.list({
        "part": ["contentDetails"],
        "mine": true
      });

      const channelId = response.result.items[0].id;

      const playlistResponse = await window.gapi.client.youtube.playlists.list({
        "part": ["contentDetails"],
        "channelId": channelId,
        "mine": true
      });

      const playlistId = playlistResponse.result.items[0].id;

      const videosResponse = await window.gapi.client.youtube.playlistItems.list({
        "part": ["snippet"],
        "playlistId": playlistId
      });

      const { data: userData, error: fetchError } = await supabase
        .from('public_users')
        .select('videos')
        .eq('id', userId)
        .single();

      if (fetchError) throw fetchError;

      const existingVideos = userData.videos || [];

      const newVideos = videosResponse.result.items.map(item => ({
        youtube: item.snippet.resourceId.videoId,
        title: item.snippet.title || '',
        description: item.snippet.description || ''
      }));

      const updatedVideos = [...existingVideos, ...newVideos];

      const { error: upsertError } = await supabase
        .from('public_users')
        .update({ videos: updatedVideos })
        .eq('id', userId);

      if (upsertError) throw upsertError;

      console.log("YouTube videos upserted successfully");
      await fetchVideos();

    } catch (error) {
      console.error("Execute error", error);
      setError("Failed to fetch YouTube videos: " + error.message);
    }
  };

  const fetchVideos = async () => {
    try {
      const session = await supabase.auth.getSession();
      const userId = session.data.session?.user.id;
  
      if (userId) {
        const { data, error } = await supabase
          .from('public_users')
          .select('videos')
          .eq('id', userId)
          .single();
  
        if (error) throw error;
  
        const videoArray = data.videos || [];
        
        const processedVideos = videoArray.map(video => {
          if (video.youtube) {
            return {
              url: `https://www.youtube.com/watch?v=${video.youtube}`,
              type: 'youtube',
              title: video.title || 'Untitled Video',
              description: video.description || '',
              thumbnail: video.thumbnail || '',
              id: video.youtube
            };
          }
          if (video.tiktok) {
            return {
              url: `https://www.tiktok.com/@user/video/${video.tiktok}`,
              type: 'tiktok',
              title: video.title || video.description || 'Untitled Video',
              description: video.description || '',
              id: video.tiktok
            };
          }
          return null;
        }).filter(Boolean);
  
        setVideos(processedVideos);
      }
    } catch (error) {
      console.error('Error fetching videos:', error.message);
      setError('Failed to fetch videos: ' + error.message);
    }
  };
  
  const handleAuthorizeAndFetchYouTube = async () => {
    try {
      await authenticate();
      await loadClient();

      const session = await supabase.auth.getSession();
      const userId = session.data.session?.user.id;

      if (userId) {
        await executeYouTube(userId);
      }
    } catch (error) {
      console.error("Error during YouTube authorization and fetching:", error);
      setError("Failed to connect YouTube account: " + error.message);
    }
  };

  async function getSubmissionCount(proposalId) {
    try {
      const { data, count, error } = await supabase
        .from('submissions')
        .select('id', { count: 'exact' })
        .eq('proposal_id', proposalId);

      if (error) throw error;
      return count || 0;
    } catch (error) {
      console.error(`Error getting submission count for proposal ${proposalId}:`, error.message);
      return 0;
    }
  }

  useEffect(() => {
    const checkUserSession = async () => {
      try {
        const { data: { session }, error } = await supabase.auth.getSession();
  
        if (error) {
          console.error('Error getting session:', error.message);
          return;
        }
  
        if (session) {
          const userUID = session.user.id;
  
          // First fetch user data and proposals
          const [userData, proposalsData] = await Promise.all([
            fetchUserData(userUID),
            fetchProposals(userUID),
            fetchSubmissions(userUID),
            fetchVideos(userUID)
          ]);
  
          // After proposals are fetched, get contributions
          if (proposalsData && proposalsData.length > 0) {
            await fetchContributions(userUID, proposalsData.map(p => p.id));
          }
  
          // Check for auth states
          if (location.state?.youtubeAuth) {
            if (location.state.youtubeAuth === 'success') {
              setError(null);
            } else if (location.state.youtubeAuth === 'error') {
              setError(location.state.error);
            }
            window.history.replaceState({}, document.title);
          }
  
          if (location.state?.tiktokAuth) {
            if (location.state.tiktokAuth === 'success') {
              setError(null);
            } else if (location.state.tiktokAuth === 'error') {
              setError(location.state.error);
            }
            window.history.replaceState({}, document.title);
          }
        }
      } catch (error) {
        console.error('Error in checkUserSession:', error);
        setError('Failed to load user session');
      }
    };
  
    checkUserSession();
  }, [navigate, location]);

  
  const handleSignOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('Error signing out:', error.message);
    } else {
      navigate('/ideas');
    }
  };

  const handleEdit = async () => {
    navigate('/onboard');
  };

  return (
    
    <div>
      <section>
        
        <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
          
          {/* {displayName} */}
          <h1 className="text-3xl font-bold text-gray-800 mb-2">Welcome, {displayName ? displayName : "Anonymous"}!</h1>

        <div className="mt-4">
        <div className="border-b border-gray-200">
              <nav className="-mb-px flex gap-6" aria-label="Tabs">
                <a
                  href="#"
                  onClick={() => handleTabClick('bounties')}
                  className={`shrink-0 border-b-2 px-1 pb-4 text-sm font-medium ${selectedTab === 'bounties' ? 'border-sky-500 text-sky-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'}`}
                >
                  My Bounties
                </a>

                <a
                  href="#"
                  onClick={() => handleTabClick('submissions')}
                  className={`shrink-0 border-b-2 px-1 pb-4 text-sm font-medium ${selectedTab === 'submissions' ? 'border-sky-500 text-sky-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'}`}
                >
                  Submissions
                </a>

                <a
                  href="#"
                  onClick={() => handleTabClick('settings')}
                  className={`shrink-0 border-b-2 px-1 pb-4 text-sm font-medium ${selectedTab === 'settings' ? 'border-sky-500 text-sky-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'}`}
                >
                  Settings
                </a>
              </nav>
            </div>
          </div>

          {/* Conditional Rendering based on Selected Tab */}
          {selectedTab === 'bounties' && (
            <>
              {proposals.length === 0 ? (
                <p className="text-gray-500 mt-2 mb-2">Your original ideas will appear here.</p>
              ) : (
                proposals.map((proposal) => (
                  <article key={proposal.id} className="rounded-xl border-2 border-gray-100 bg-white mt-2 mb-2 max-w-lg">
                    <div className="flex items-start justify-between p-4 sm:p-6 lg:p-8">
                      <div className="flex-1">
                        <p className="font-medium">
                          <Link to={`/expanded-proposal/${proposal.id}`}>
                            {proposal.title}
                          </Link>
                        </p>
                        <p className="text-sm">Number of Submissions: {proposal.submissionCount}</p>
                        <p className="text-sm">Votes: {proposal.votes || 0}</p>
                        <p className="text-sm">
                          You Contributed: ${((proposalContributions[proposal.id]?.user) || 0).toFixed(2)}
                        </p>
                        <p className="text-sm">
                          Total Contributed: ${((proposalContributions[proposal.id]?.total) || 0).toFixed(2)}
                        </p>
                      </div>
                    </div>
                  </article>
                ))
              )}
            </>
          )}


          {selectedTab === 'submissions' && (
            <>
              {submissions.length === 0 ? (
                <p className="text-gray-500 mt-2 mb-2">Your videos will appear here.</p>
              ) : (
                submissions.map((submission) => (
                  <article key={submission.id} className="rounded-xl border-2 border-gray-100 bg-white mt-2 mb-2 max-w-lg">
                    <div className="flex items-start justify-between p-4 sm:p-6 lg:p-8">
                      <div className="flex-1">
                        <p className="font-medium">
                          <Link to={`/expanded-proposal/${submission.proposal_id}`}>
                            {submission.title}
                          </Link>
                        </p>
                        <p className="text-sm">Video Link: <Link to={submission.link}>{submission.link}</Link></p>
                        <p className="text-sm">Votes: {submission.votes}</p>
                        <p className="text-sm">Expected Payout: $0</p>
                        <p className="text-sm">Max Payout: $0</p>
                      </div>
                    </div>
                  </article>
                ))
              )}
            </>
          )}

{selectedTab === 'settings' && (
  <>
    {/* First section: User Preferences */}
    <h1 className="text-2xl font-bold text-gray-800 mt-2 mb-2">Preferences</h1>
    <p>Display Name: {displayName ? displayName : "Anonymous"}</p>
    <p>Account Type: {user.account_type ? user.account_type : "Viewer"}</p>
    <p>Preferred Platform: {user.preferred_platform || "YouTube, TikTok"}</p>
    <button onClick={handleEdit} className="mt-2 mb-4 inline-block rounded bg-teal-600 px-4 py-2 text-sm font-medium text-white hover:bg-teal-700 focus:outline-none focus:ring">
      Manage Preferences
    </button>

    {/* Second section: Creator Settings */}
    <h1 className="text-2xl font-bold text-gray-800 mt-2 mb-2">Creator Settings</h1>
    <div>
      <h2 className="text-l text-gray-800 mb-4">Link your accounts to post your videos on Bounties.</h2>
      
      {/* TikTok connection success message */}
      {location.state?.tiktokAuth === 'success' && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4">
          {location.state.message}
        </div>
      )}

      {/* Connection Buttons and Status Section */}
      <div className="flex flex-col gap-4 mb-4">
        {/* YouTube Connection Container */}
        <div className="flex flex-col items-start">
          <button
            onClick={request_youtube_token}
            className="authorize-button rounded border border-red-600 hover:bg-red-600 px-4 py-2 text-sm font-medium active:bg-transparent text-red-600 hover:text-white focus:outline-none focus:ring active:text-red-500"
          >
            Connect YouTube Channel
          </button>
          {user.platforms.youtube.connected && (
            <p className="text-sm text-gray-600 mt-1">
              Last synced: {formatDate(user.platforms.youtube.last_sync)}
            </p>
          )}
        </div>

        {/* TikTok Connection Container */}
        <div className="flex flex-col items-start">
          <button 
            onClick={request_token} 
            className="rounded border border-slate-900 px-4 py-2 text-sm font-medium text-gray-600 hover:text-white hover:bg-slate-900 focus:outline-none focus:ring active:text-red-500"
            disabled={isProcessingTikTok}
          >
            {isProcessingTikTok ? 'Connecting...' : 'Connect TikTok Account'}
          </button>
          {user.platforms.tiktok.connected && (
            <p className="text-sm text-gray-600 mt-1">
              Last synced: {formatDate(user.platforms.tiktok.last_sync)}
            </p>
          )}
        </div>
      </div>
      {/* Video List Section - Conditional Rendering */}
      {videos && videos.length > 0 ? (
        <div className="mb-4">
          <h3 className="text-md font-semibold mb-2">Your Videos:</h3>
          <ul className="space-y-2">
            {videos.map((video, index) => (
              <li key={`video-${index}`} className="flex items-center p-2 border rounded hover:bg-gray-50">
                <span className={`
                  inline-flex items-center px-2 py-1 text-xs font-semibold rounded mr-2 
                  ${video.type === 'youtube' ? 'bg-red-100 text-red-800' : 'bg-black text-white'}
                `}>
                  {video.type.toUpperCase()}
                </span>
                <div className="flex-1 min-w-0"> {/* min-w-0 ensures proper truncation */}
                  <a 
                    href={video.url}
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="text-blue-600 hover:underline block font-medium"
                  >
                    {/* Truncate title if longer than 60 characters */}
                    {video.title ? 
                      (video.title.length > 60 ? 
                        `${video.title.substring(0, 60)}...` : 
                        video.title) : 
                      'Untitled Video'
                    }
                  </a>
                  {video.description && (
                    <p className="text-sm text-gray-600 truncate max-w-full">
                      {/* Truncate description to 100 characters */}
                      {video.description.length > 100 ? 
                        `${video.description.substring(0, 100)}...` : 
                        video.description
                      }
                    </p>
                  )}
                </div>
                {video.type === 'youtube' && video.thumbnail && (
                  <div className="flex-shrink-0 ml-2">
                    <img 
                      src={video.thumbnail} 
                      alt={video.title} 
                      className="w-20 h-14 object-cover rounded"
                      loading="lazy"
                    />
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <p className="text-gray-500 mb-4">No videos connected.</p>
      )}

      {/* Sign Out Button */}
      <button 
        onClick={handleSignOut} 
        className="mt-4 signout-button inline-block rounded border border-red-600 bg-red-600 px-4 py-2 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring active:text-red-500"
      >
        Sign Out
      </button>
    </div>

    {/* Additional TikTok success message outside the main settings div */}
    {location.state?.tiktokAuth === 'success' && (
      <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4">
        {location.state.message}
      </div>
    )}
  </>
)}
        </div>
      </section>
    </div>
  );
}