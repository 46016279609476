import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { createClient } from '@supabase/supabase-js';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_PROJECT_URL,
  process.env.REACT_APP_SUPABASE_KEY
);

const YouTubeRedirect = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState('Processing YouTube authorization...');
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleYouTubeCallback = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const state = urlParams.get('state');

        if (!code) {
          throw new Error('No authorization code received');
        }

        setStatus('Exchanging code for access token...');

        const response = await axios.post(
          'https://bountiesapp.com/server/youtubeaccesstoken',
          { code, state },
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );

        if (!response.data) {
          throw new Error('No data received from server');
        }

        setStatus('Getting user session...');
        
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();
        if (sessionError) throw sessionError;
        
        const userId = session?.user?.id;
        if (!userId) {
          throw new Error('No user session found');
        }

        setStatus('Processing videos...');

        // Get user data including existing videos and platforms
        const { data: userData, error: fetchError } = await supabase
          .from('public_users')
          .select('videos, platforms')
          .eq('id', userId)
          .single();

        if (fetchError) throw fetchError;

        // Process new videos
        const newVideos = response.data.map(video => ({
          youtube: video.id,
          title: video.title || 'Untitled Video',
          description: video.description || '',
          thumbnail: video.thumbnail_url || '',
          publishedAt: video.published_at || new Date().toISOString(),
          type: 'youtube'
        })).filter(Boolean);

        const existingVideos = userData?.videos || [];
        
        // Combine videos while avoiding duplicates
        const updatedVideos = [
          ...existingVideos,
          ...newVideos.filter(newVideo => 
            !existingVideos.some(existingVideo => 
              existingVideo.youtube === newVideo.youtube
            )
          )
        ];

        // Update platforms status
        const currentPlatforms = userData?.platforms || {
          youtube: { connected: false, last_sync: null },
          tiktok: { connected: false, last_sync: null }
        };

        const updatedPlatforms = {
          ...currentPlatforms,
          youtube: {
            connected: true,
            last_sync: new Date().toISOString()
          }
        };

        setStatus('Saving to database...');

        // Update both videos and platforms status
        const { error: upsertError } = await supabase
          .from('public_users')
          .update({ 
            videos: updatedVideos,
            platforms: updatedPlatforms
          })
          .eq('id', userId);

        if (upsertError) {
          console.error('Upsert error:', upsertError);
          throw upsertError;
        }

        setStatus('Success! Redirecting...');

        navigate('/profile', { 
          state: { 
            youtubeAuth: 'success',
            message: `Successfully connected YouTube account with ${newVideos.length} videos`,
            videoCount: newVideos.length
          }
        });

      } catch (error) {
        console.error('YouTube callback error:', error);
        setError(error.message || 'An error occurred during YouTube authorization');
        setStatus('Error occurred. Redirecting...');
        
        navigate('/profile', { 
          state: { 
            youtubeAuth: 'error',
            error: error.message || 'Failed to connect YouTube account'
          }
        });
      }
    };

    handleYouTubeCallback();
  }, [navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            YouTube Authorization
          </h2>
          <div className="mt-2 text-center text-sm text-gray-600">
            <p className="font-medium">{status}</p>
            {error && (
              <p className="mt-2 text-red-600">
                {error}
              </p>
            )}
          </div>
          {!error && (
            <div className="mt-4 flex justify-center">
              <svg className="animate-spin h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default YouTubeRedirect;