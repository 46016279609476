import '../index.css';
import React, { useEffect, useState } from 'react';
import { createClient } from '@supabase/supabase-js';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { useNavigate } from 'react-router-dom';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_PROJECT_URL,
  process.env.REACT_APP_SUPABASE_KEY
);

export default function Login() {
  const [session, setSession] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    let mounted = true;

    // Check if user exists in public_users table
    const checkUserExists = async (userId) => {
      try {
        const { data, error } = await supabase
          .from('public_users')
          .select('id')
          .eq('id', userId)
          .maybeSingle();

        if (error && error.code !== 'PGRST116') {
          throw error;
        }

        return !!data;
      } catch (error) {
        console.error('Error checking user existence:', error);
        return false;
      }
    };

    // Create or update user in public_users table
    const upsertUser = async (user) => {
      try {
        const userData = {
          id: user.id,
          email: user.email,
          display_name: user.user_metadata?.name || 
                       user.user_metadata?.full_name || 
                       user.email?.split('@')[0] || 
                       'Anonymous'
        };

        const { error } = await supabase
          .from('public_users')
          .upsert(userData, {
            onConflict: 'id',
            returning: 'minimal'
          });

        if (error) throw error;
      } catch (error) {
        console.error('Error upserting user:', error);
        throw error;
      }
    };

    // Handle initial session
    const initializeSession = async () => {
      try {
        const { data: { session }, error } = await supabase.auth.getSession();
        if (error) throw error;
        
        if (mounted) {
          setSession(session);
          if (session?.user) {
            const exists = await checkUserExists(session.user.id);
            await upsertUser(session.user);
            if (!exists) {
              navigate('/onboard', { replace: true });
            } else {
              navigate('/ideas', { replace: true });
            }
          }
        }
      } catch (error) {
        console.error('Error initializing session:', error);
      } finally {
        if (mounted) {
          setIsLoading(false);
        }
      }
    };

    initializeSession();

    // Listen for auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        console.log('Auth state changed:', event);
        
        if (mounted) {
          setSession(session);
          
          if (event === 'SIGNED_IN' && session?.user) {
            setIsLoading(true);
            try {
              const exists = await checkUserExists(session.user.id);
              await upsertUser(session.user);
              
              if (!exists) {
                navigate('/onboard', { replace: true });
              } else {
                navigate('/ideas', { replace: true });
              }
            } catch (error) {
              console.error('Error handling auth state change:', error);
              alert('There was an error processing your login. Please try again.');
            } finally {
              setIsLoading(false);
            }
          } else if (event === 'SIGNED_OUT') {
            navigate('/', { replace: true });
          }
        }
      }
    );

    return () => {
      mounted = false;
      subscription.unsubscribe();
    };
  }, [navigate]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="text-xl">Loading...</div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-sm">
        {session ? (
          <div className="text-center">
            <h1 className="text-2xl font-bold mb-4">Logged in!</h1>
            <p>Redirecting you...</p>
          </div>
        ) : (
          <Auth 
            supabaseClient={supabase}
            appearance={{ theme: ThemeSupa }}
            providers={['google']}
            onError={(error) => {
              console.error('Auth error:', error);
              alert('Authentication error. Please try again.');
            }}
          />
        )}
      </div>
    </div>
  );
}