import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { createClient } from "@supabase/supabase-js";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from '@dnd-kit/core';

import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';

import {
  defaultAnimateLayoutChanges,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
const supabase = createClient(process.env.REACT_APP_SUPABASE_PROJECT_URL, process.env.REACT_APP_SUPABASE_KEY);

// SortableLink Component
const SortableLink = ({ id, url, onDelete, onUrlChange }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <div 
      ref={setNodeRef} 
      style={style} 
      className="flex items-center gap-2 mb-2 group"
    >
      <div 
        {...attributes} 
        {...listeners}
        className="cursor-grab p-2 hover:bg-gray-100 rounded"
      >
        ⋮⋮
      </div>
      <input
        type="url"
        value={url}
        onChange={(e) => onUrlChange(id, e.target.value)}
        className="flex-1 rounded-lg border-gray-200 p-3 text-sm"
        placeholder="https://link-to-supporting-information"
        autocomplete="one-time-code" 
      />
      <button
        onClick={() => onDelete(id)}
        className="p-2 text-red-500 sm-opacity-0 group-hover:opacity-100 transition-opacity"
        type="button"
      >
        ×
      </button>
    </div>
  );
};

export default function SubmitIdea() {
  const location = useLocation();
  const navigate = useNavigate();
  const [oneLiner, setOneLiner] = useState('');
  const [platform, setPlatform] = useState('');
  const [links, setLinks] = useState([]);
  const [userUID, setUserUID] = useState(null);
  const [expiration, setExpiration] = useState(new Date(new Date().setDate(new Date().getDate() + 1)));
  const [isDateValid, setIsDateValid] = useState(true);
  const [activeId, setActiveId] = useState(null);
  const [selectedDurations, setSelectedDurations] = useState([]);
  const [daysUntilExpiration, setDaysUntilExpiration] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const durationInfo = {
    SHORT: {
      label: 'Short (< 3 mins)',
      description: 'Best for Shorts, Reels, or TikTok'
    },
    MEDIUM: {
      label: 'Medium (5 - 25 mins)',
      description: 'Best for reviews, gameplay, etc.'
    },
    LONG: {
      label: 'Long (30+ mins)',
      description: 'Best for in-depth content'
    }
  };

  useEffect(() => {
    if (location.state?.idea) {
      setOneLiner(location.state.idea);
    }
  }, [location.state]);

  const toggleDuration = (durationType) => {
    setSelectedDurations(prev => {
      if (prev.includes(durationType)) {
        return prev.filter(d => d !== durationType);
      } else {
        return [...prev, durationType];
      }
    });
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    async function fetchUser() {
      const { data: { user }, error } = await supabase.auth.getUser();
      if (error) {
        console.error('Error fetching user:', error.message);
        return;
      }
      if (user) {
        setUserUID(user.id);
      } else {
        navigate('/login');
      }
    }
    fetchUser();
  }, [navigate]);

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setLinks((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
    setActiveId(null);
  };

  const addLink = () => {
    const newLink = {
      id: `link-${Date.now()}`,
      url: ''
    };
    setLinks([...links, newLink]);
  };

  const deleteLink = (id) => {
    setLinks(links.filter(link => link.id !== id));
  };

  const updateLinkUrl = (id, newUrl) => {
    setLinks(links.map(link => 
      link.id === id ? { ...link, url: newUrl } : link
    ));
  };

  const handleDateChange = (date) => {
    setExpiration(date);
    const isValid = date > new Date();
    setIsDateValid(isValid);
    if (isValid) {
      const diffInTime = date.getTime() - new Date().getTime();
      const diffInDays = Math.ceil(diffInTime / (1000 * 3600 * 24));
      setDaysUntilExpiration(diffInDays);
    }
  };

  async function submit(e) {
    e.preventDefault();
    if (!userUID) {
      alert('You must be logged in to submit an idea.');
      return;
    }

    if (!isDateValid) {
      alert('Please choose a valid date before submitting.');
      return;
    }

    setIsSubmitting(true);

    try {
      const formattedExpiration = expiration.toISOString();
      const description = links.map(link => link.url).join('\n');

      const { data, error } = await supabase
        .from('proposals')
        .insert([
          { 
            title: oneLiner, 
            description: description,
            platform: platform, 
            created_by: userUID, 
            expiration: formattedExpiration,
            duration: selectedDurations.join(',')
          }
        ])
        .select()
        .single();

      if (error) throw error;

      navigate(`/expanded-proposal/${data.id}`);
    } catch (error) {
      console.error('Error submitting idea:', error.message);
      alert('Error submitting idea. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <div>
      <section className="bg-gray-100">
        <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
          <div className="grid grid-colzs-1 gap-x-16 gap-y-8 lg:grid-cols-5">
            <div className="lg:col-span-2 lg:py-12">
              <p className="max-w-xl text-lg">
                Submit your original video idea.
              </p>
            </div>
            <div className="rounded-lg bg-white p-8 shadow-lg lg:col-span-3 lg:p-12">
              <form onSubmit={submit} className="space-y-4">
                {/* One-liner input */}
                <div>
          
                  <label htmlFor="oneLiner" className="sr-only">One-liner describing your idea</label>
                  <input
                    type="text"
                    id="oneLiner"
                    className="w-full rounded-lg border-gray-200 p-3 text-sm"
                    placeholder="A one-liner describing your idea"
                    value={oneLiner}
                    onChange={(e) => setOneLiner(e.target.value)}
                    autocomplete="one-time-code" 
                    autoComplete='off'
                    required
                  />
                </div>

                {/* Platform selection */}
                <p>Which platform should this content be posted on?</p>
                <div className="grid grid-cols-1 gap-4 text-center sm:grid-cols-3">
                  {/* Platform radio buttons remain the same */}
                  {['YouTube', 'TikTok', 'Any'].map((platformOption) => (
                    <div key={platformOption.toLowerCase()}>
                      <input
                        type="radio"
                        id={platformOption.toLowerCase()}
                        className="peer sr-only"
                        name="platform"
                        value={platformOption}
                        checked={platform === platformOption}
                        onChange={(e) => setPlatform(e.target.value)}
                        required
                      />
                      <label
                        htmlFor={platformOption.toLowerCase()}
                        className="block w-full rounded-lg border border-gray-200 p-3 text-gray-600 hover:border-black peer-checked:border-black peer-checked:bg-black peer-checked:text-white"
                        tabIndex="0"
                      >
                        <span className="text-sm">{platformOption}</span>
                      </label>
                    </div>
                  ))}
                </div>
                <p>How long should this video be?</p>
                <div>
  <div>
  
  <div className="grid grid-cols-1 gap-4 text-center sm:grid-cols-3">
    {Object.entries(durationInfo).map(([durationType, info]) => (
      <div key={durationType}>
        <input
          type="checkbox"
          id={`duration-${durationType.toLowerCase()}`}
          className="peer sr-only"
          checked={selectedDurations.includes(durationType)}
          onChange={() => toggleDuration(durationType)}
        />
        <label
          htmlFor={`duration-${durationType.toLowerCase()}`}
          className={`block w-full rounded-lg border border-gray-200 p-3 text-gray-600 hover:border-black 
            peer-checked:border-black peer-checked:bg-black peer-checked:text-white
            cursor-pointer transition-colors duration-200`}
          tabIndex="0"
        >
          <span className="text-sm font-medium">
            {info.label}
          </span>
          <div className={`mt-2 text-xs ${selectedDurations.includes(durationType) ? 'text-gray-200' : 'text-gray-500'}`}>
            {info.description}
          </div>
        </label>
      </div>
    ))}
  </div>
  {selectedDurations.length === 0 && (
    <p className="mt-2 text-xs text-gray-500">
      Select at least one recommended duration.
    </p>
  )}
</div>
                </div> 

                {/* Date picker */}
                <div>
                  <p>Give a deadline for creators to make your idea. </p>
                  <DatePicker
                    selected={expiration}
                    onChange={handleDateChange}
                    dateFormat="Pp"
                    className="w-full rounded-lg border-gray-200 p-3 text-sm mt-2"
                  />
                  {!isDateValid ? (
                    <p className="text-red-500 text-sm font-semibold mt-2">Choose a valid date.</p>
                  ) : (
                    <p className="text-gray-400 text-sm font-semibold mt-2">
                      Creators have <span className='text-red-600'> {daysUntilExpiration} {daysUntilExpiration === 1 ? 'day' : 'days'}</span> to make your idea.
                    </p>
                  )}
                </div>

                {/* Links section */}
                <div>
                  <p className="mb-2">Add links to helpful resources about your idea (optional):</p>
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                    modifiers={[restrictToVerticalAxis]}
                  >
                    <SortableContext
                      items={links.map(link => link.id)}
                      strategy={verticalListSortingStrategy}
                    >
                      {links.map((link) => (
                        <SortableLink
                          key={link.id}
                          id={link.id}
                          url={link.url}
                          onDelete={deleteLink}
                          onUrlChange={updateLinkUrl}
                        />
                      ))}
                    </SortableContext>
                  </DndContext>
                  
                  <button
                    type="button"
                    onClick={addLink}
                    className="mt-2 text-sm text-gray-600 hover:text-black underline"
                  >
                    + Add Link
                  </button>
                </div>

                {/* Submit button */}
                <div className="mt-4">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className={`inline-block w-full rounded-lg bg-black px-5 py-3 font-medium text-white sm:w-auto ${
                      isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                  >
                    {isSubmitting ? 'Submitting...' : 'Submit Idea!'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}